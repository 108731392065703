export const Projects = [
  {
    weblink: "https://taskmasterusa.com/",
    image: "../images/taskmaster.png",
    imagealt: "TaskMAsterUSA",
    title: "TaskMAsterUSA",
    git: "https://github.com/jgotti1/TaskMasterUSA.git",
    mobile: "YES",
    language: "MongoDB, Express, React, Node, Javascript, CSS (MERN Stack App)",
    type: " JavaScript Game",
    overview: " My final milestone project at NJIT. Was a student, team final project.",
    description: `An App created for a business to track and assign employee tasks. 
    Try our demo accounts out... ADMIN demo@gmail.com password: Password123!
    Regular User Login: mike@gmail.com password: Password123!`
  },
  {
    weblink: "https://monkeygame.margottiworld.com/",
    image: "../images/MonkeyGame.png",
    imagealt: "MonkeyGame",
    title: "Crazy Monkey",
    git: "https://github.com/jgotti1/CatchTheMonkey.git",
    mobile: "NO",
    language: " JavaScript, HTML, CSS",
    type: " JavaScript Game",
    overview: " My first milestone project at NJIT. Challenging but fun to create.",
    description: " Feed the crazy monkey as many banannas as you can before time expires. NOT Mobile Device friendly. Try it out.",
  },
  {
    weblink: "https://dicegame.margottiworld.com/",
    image: "../images/dice.png",
    imagealt: "Dice Game",
    title: "Dice Mania",
    git: "https://github.com/jgotti1/DiceRoll100.git",
    mobile: "NO",
    language: "JavaScript, HTML, CSS",
    type: "JavaScript Game",
    overview: "A project to practice my Javascript skills.",
    description: "A two player game. Roll the dice, the first player to reach 100 before rolling a 1 wins. NOT Mobile Device friendly. Try it out.",
  },
  {
    weblink: "https://poppymatch.margottiworld.com/",
    image: "../images/poppy.png",
    imagealt: "Poppy Playtime",
    title: "Poppy Playtime Match",
    git: "https://github.com/jgotti1/React-Memory-Game.git",
    mobile: "YES",
    language: "React, JavaScript, CSS",
    type: "React JavaScript Game",
    overview: "A game I did for my grandson to play on his IPad.",
    description: "Did this character matching game project for my grandson when Poppy Playtime was popular. Try it out, enjoy!",
  },
  {
    weblink: "https://pickanumber.margottiworld.com/",
    image: "../images/number.png",
    imagealt: "Guess my Number",
    title: "Guess my Number",
    git: "https://github.com/jgotti1/PickANumber.git",
    mobile: "NO",
    language: "JavaScript, CSS, HTML",
    type: "JavaScript Game",
    overview: "A basic Javascript game I put together to practice my JS skills.",
    description: "Guess the number between 1-20 you have 20 guesses so good luck ! Try it out.",
  },
  {
    weblink: "https://ravmdesign.com/",
    image: "../images/RAVM.png",
    imagealt: "RAVM Construction",
    title: "RAVM Construction",
    git: "https://github.com/jgotti1/ravm_comstruction.git",
    mobile: "YES",
    language: "React, Javascript, CSS",
    type: "Contractor company web site",
    overview: "A multi-page web site I put together and hosted for a local contractor.",
    description: "Local contractor that does awesome work needed a web site so here it is. Check it out.",
  },
  {
    weblink: "https://noahryleigh.margottiworld.com/",
    image: "../images/selfie.png",
    imagealt: "Selfies with Noah and Ryleigh",
    title: "Selfies with Noah and Ryleigh",
    git: "https://github.com/jgotti1/Noah-Ryleigh-Selfie.git",
    mobile: "YES",
    language: "React, Javascript, CSS",
    type: "Personal photo site",
    overview: "A multi-page web site I put together to display selfies with my two grandchildren.",
    description: "I had a phone full of selfies with the grand-kids so why not build a app to put them on display. Check it out !",
  },
  {
    weblink: "https://crossfittrack.margottiworld.com/",
    image: "../images/crossfit.png",
    imagealt: "Crossfit Workout Tracker",
    title: "Crossfit Workout Tracker",
    git: "https://github.com/jgotti1/mern-crossfit-workouts.git",
    mobile: "YES",
    language: "MongoDB, Express, React, Node, Javascript, CSS (MERN Stack App)",
    type: "Personal Workout Tracker",
    overview: "Full stack application that could be used to store and track your Crossfit workouts.",
    description: "Wanted to track my workouts so I built this cool app. Click on the link, sign up and try it out today!",
  },
  {
    weblink: "https://citrusproperty.herokuapp.com",
    image: "../images/prop.png",
    imagealt: "Citrus Property Management",
    title: "Citrus Property Management",
    git: "https://github.com/jgotti1/milestoneTwo.git",
    mobile: "YES",
    language: "MongoDB, Express, React, Node, Javascript, CSS (MERN Stack App)",
    type: "RealEstate rental property tracking app",
    overview: "App to help manage rental properties",
    description: "Second milestone project for the NJIT program. As a team of three we created this full stack property management app. Sign-up to try it out."
  },
  {
    weblink: "https://taskmanager.margottiworld.com/",
    image: "../images/task.png",
    imagealt: "Task Manager",
    title: "Task Manager",
    git: "https://github.com/jgotti1/Mern-Task-Manager.git",
    mobile: "YES",
    language: "MongoDB, Express, React, Node, Javascript, CSS (MERN Stack App)",
    type: "Task tracking APP",
    overview: "App to help manage and assigncompany tasks",
    description: "Built this app as a way for a business or person to track and assign tasks to anyone signed up as member.It can be customized for any need"
  },
  {
    weblink: "https://jgotti1.github.io/SoccerBallHit/",
    image: "../images/soccer.png",
    imagealt: "Soccer Fun",
    title: "Soccer Fun",
    git: "https://github.com/jgotti1/SoccerBallHit.git",
    mobile: "NO",
    language: "HTML, Javascript, CSS",
    type: "Browser Game",
    overview: "Spin-off of the earlier Monkey game",
    description: "Fun spin-off of the other Javascript browser game but here you have to hit the goalie with the ball as many times as possible before time expires"
  },
  {
    weblink: "https://artgallery.margottiworld.com/",
    image: "../images/art.png",
    imagealt: "Art Gallery",
    title: "Art Gallery",
    git: "https://github.com/jgotti1/React-Art-Gallery-API.git",
    mobile: "NO",
    language: "React, Javascript, CSS",
    type: "API App",
    overview: "Art Gallery of images",
    description: "Another NJIT bootcamp project. Created a display of art from a Met Museum API"
  },

  {
    weblink: "https://photoflip.margottiworld.com/",
    image: "../images/flip.png",
    imagealt: "Photo Flip",
    title: "Photo Flip",
    git: "https://github.com/jgotti1/NoahRyFlipper.git",
    mobile: "NO",
    language: "HTML, Javascript, CSS",
    type: "JavaScript Practice App",
    overview: "Javascript random image selector",
    description: "NJIT class App to randomly choose a photo and count the times and percentage that each photo appears"
  },
];
